// Import React dependencies and necessary components & tools
import {useContext, useEffect, useState} from "react";
import {SECTIONS} from "../util/product-sections.js";
import SectionControl from "../components/SectionControl.jsx";
import Products from "../components/Products.jsx";
import {getProductsBySection} from "../util/getProductBySections.js";
import PageHeading from "../components/PageHeading.jsx";
import Camera from "../components/tryon/Camera.jsx";
import "../css/tryon.css";
import "../css/tryon/p-clipboard-tooltip.css";
import ProductsMobile from "../components/ProductsMobile.jsx";
import SectionTitle from "../components/SectionTitle.jsx";
import {LoremIpsum} from "../components/LoremIpsum.jsx";
import {setAgulio} from "../util/agulio.js";
import PresetTryOn from "../components/tryon/PresetTryOn.jsx";
import {Checkout} from "../components/tryon/Checkout.jsx";
import PreContext from "../context/PresetContext.jsx";
import LookContext from "../context/LookCtx.jsx";
import SectionContext from "../context/SectionCtx.jsx";
import {initialSection} from "../context/SectionCtx.jsx";
import PresetImage from "../components/tryon/PresetImage.jsx";
import preset from "../util/preset.json";

export default function Tryon({setPage}) {
    const cases = preset[0].items;
    const initialProducts = initialSection
        ? getProductsBySection(initialSection)
        : undefined;
    const initalUserActions = JSON.parse(localStorage.getItem("tryOnUSer")) || [
        {
            section: "lips",
            product: {
                section: "lips",
                code: "AGRYLICR002",
                productName: "BABY DOLL CREAMY LIPSTICK",
                description: "#7B3D3D",
                hexColor: "#7B3D3D",
                ecommerceLinkITA:
                    "https://www.australiangold.it/makeup/422-lipstick-baby-doll.html",
                ecommerceLinkENG:
                    "https://www.australiangold.net/gb/home/404-creamy-lipstick-baby-doll.html",
                id: 406,
                cost: "23.90",
                image:
                    "https://www.australiangold.it/1183-pdt_540/lipstick-baby-doll.jpg",
            },
        },
    ];
    const initialCurrentProduct =
        JSON.parse(localStorage.getItem("currentProduct")) || undefined;
    let storedValue =
        (localStorage.getItem("ProductDataItemID") &&
            localStorage.getItem("ProductDataItemID").split(",")) ||
        [];
    // State to manage the current section, current products, selected product and userActions
    const {currentSection, setCurrentSection} = useContext(SectionContext);
    const [currentProducts, setCurrentProducts] = useState();
    const [productSelected, setProductSelected] = useState(initialCurrentProduct);

    const {currentPreset, setCurrentPreset} = useContext(PreContext);
    const [dataItemId, setDataItemId] = useState(storedValue ? storedValue : []);
    //our preset category
    const {currentLook, setCurrentLook} = useContext(LookContext);
    const currentCase = currentLook;
    const setCurrentCase = setCurrentLook;
    const [userActions, setUserActions] = useState(initalUserActions);
    const [windowSize, setWindowSize] = useState({
        width: window.innerWidth,
        height: window.innerHeight,
    });
    if (productSelected) {
        setAgulio();
    }
    console.log(currentSection, 'currentSection')
    if (
        currentSection === "preset" &&
        currentPreset &&
        currentPreset.presetName !== undefined
    ) {
        localStorage.setItem("currentSection", currentSection);
        setAgulio();
    }

    const logUserAction = (type, value) => {
        let userAction;
        if (type === "selectSection") {
            userAction = {
                section: value,
                product: productSelected,
            };
        } else if (type === "selectProduct") {
            userAction = {
                section: currentSection,
                product: value,
            };
        }
        let newUserActions = userActions || undefined;
        if (newUserActions) {
            newUserActions = [...userActions, userAction];
        } else {
            newUserActions = [userAction];
        }
        setUserActions(newUserActions);
        //console.log(newUserActions,"new action")
        localStorage.setItem("tryOnUSer", JSON.stringify(newUserActions));
    };
    // Effect to update products when the section changes
    useEffect(() => {
        if (currentSection === undefined) {
            return;
        }
        // Get new products and select the first product
        const newProducts = getProductsBySection(currentSection);
        setCurrentProducts(newProducts);
        //console.log(newProducts, newProductSelected);
    }, [currentSection]);

    // Handles selecting a product
    function handleSelectProduct(index) {
        const newSelectedProduct = getProductsBySection(currentSection)[index];
        if (productSelected === newSelectedProduct) {
            return;
        }
        const newDataItemsId = [...dataItemId, newSelectedProduct.id];
        console.log(index)

        setDataItemId(newDataItemsId);
        setProductSelected(newSelectedProduct);
        logUserAction("selectProductTry", newSelectedProduct.productName);

        localStorage.setItem("ProductDataItemID", newDataItemsId);
        localStorage.setItem("currentProduct", JSON.stringify(newSelectedProduct));
        window.location.reload();
    }

    // Handles selecting a section
    function handleSelectSection(idx) {
        const newSection = SECTIONS[idx];


        if (currentSection === "preset") {
            setDataItemId([]);
        } // Check if the selected section is already the current one
        if (newSection === currentSection) {
            setCurrentSection();
            localStorage.setItem("currentSection", newSection);
            return;
        }
        localStorage.setItem("currentSection", newSection);
        setCurrentSection(newSection);
        setProductSelected(undefined);
        logUserAction("selectSection", newSection);
        if (currentSection === "preset") {
            localStorage.removeItem("ProductDataItemID");
        }
    }

    function handleBack() {
        // console.log("back!");
        if (currentSection === "preset") {

            handleSelectSection(0)
            return
        }

        const newUserHistory = [...userActions];
        newUserHistory.pop();
        if (newUserHistory.length === 0) {
            setProductSelected();
            setUserActions(newUserHistory);
            localStorage.removeItem("tryOnUSer");
            return;
        }
        const lastAction = newUserHistory[newUserHistory.length - 1];
        let backSection;
        let backProduct;

        if (lastAction && lastAction.section) {
            backSection = lastAction.section;
        }
        if (lastAction && lastAction.product) {
            backProduct = lastAction.product;
        }
        setCurrentSection(backSection);
        setProductSelected(backProduct);
        if (backSection) {
            localStorage.setItem("currentSection", backSection);
        }

        if (productSelected && backProduct) {
            localStorage.setItem("selectedProductOnTryOn", backProduct.productName);
        }
        setUserActions(newUserHistory);
        //console.log(newUserHistory,"new user history")
        localStorage.setItem("tryOnUSer", JSON.stringify(newUserHistory));
        if (!backProduct) {
            onReset();
        }
    }

    // Reload the page
    function onReset() {
        window.location.reload();
    }

    function handleReset() {
        localStorage.removeItem("currentPresetTryOn");
        localStorage.removeItem("currentProduct");
        localStorage.removeItem("tryOnUSer");
        localStorage.removeItem("ProductDataItemID");
        localStorage.removeItem("currentCase");
        window.location.reload();
    }

    const [isCopyToolkit, setIsCopyToolkit] = useState();

    function copyProductColor() {
        const pColor = productSelected.hexColor;
        navigator.clipboard.writeText(pColor);
        setIsCopyToolkit(true);
        setTimeout(() => {
            setIsCopyToolkit(false);
        }, 1500);
    }

    return (
        <>
            <main id="tryon" className="py-16">
                <PageHeading
                    onBack={handleBack}
                    userActions={userActions}
                    currentSection={currentSection}
                    productSelected={productSelected}
                    onReload={onReset}
                />
                <section id="dekstop-version" className="flex  flex-col lg:flex-row  ">
                    <div className="bg-vector"></div>
                    <section id="wrapper">
                        <section id="sections">
                            <SectionTitle currentSection={currentSection}></SectionTitle>
                            <section id="wrapper-2">
                                <section style={{width: "80%"}}>
                                    <section>
                                        <h3 className="uppercase text-bold">Free virtual tryon</h3>
                                        <SectionControl
                                            isHidden={true}
                                            currentSection={currentSection}
                                            updateCurrentSectionState={handleSelectSection}
                                        />
                                    </section>
                                    {currentSection && (
                                        <section
                                            id="product-wrapper"
                                            style={{
                                                display: productSelected && "flex",
                                                justifyContent: "center",
                                            }}
                                        >
                                            {currentProducts !== undefined &&
                                            currentSection !== "preset" ? (
                                                <Products
                                                    currentProducts={currentProducts}
                                                    selectProduct={handleSelectProduct}
                                                    productSelected={productSelected && productSelected}
                                                />
                                            ) : (
                                                currentSection === "preset" && (
                                                    <PresetTryOn
                                                        updateDataItems={setDataItemId}
                                                        updateCurrentPreset={setCurrentPreset}
                                                        currentPreset={currentPreset}
                                                        currentCase={currentCase}
                                                        updateCurrentCase={setCurrentCase}
                                                    />
                                                )
                                            )}
                                            {currentProducts !== undefined &&
                                                currentSection !== "preset" && (
                                                    <section
                                                        className="flex mt-10 justify-end content-center ps-0 pe-8 md:ps-12 md:pe-4 gap-6 h-52 w-72">
                                                        {productSelected && currentSection !== "preset" && (
                                                            <>
                                                                <div className="w-7/12 h-36" id="product-image">
                                                                    <div
                                                                        className="w-full h-full border-2"
                                                                        style={{
                                                                            borderColor: productSelected.hexColor,
                                                                        }}
                                                                    >
                                                                        <a
                                                                            className="cursor-pointer"
                                                                            href={productSelected.image}
                                                                            target="_blank"
                                                                        >
                                                                            <img
                                                                                src={productSelected.image}
                                                                                className="h-full w-full object-cover"
                                                                            />
                                                                        </a>
                                                                    </div>
                                                                </div>
                                                                <div
                                                                    className="w-2/12 h-36 cursor-pointer"
                                                                    onClick={copyProductColor}
                                                                    style={{
                                                                        backgroundColor: `${
                                                                            productSelected &&
                                                                            productSelected.hexColor
                                                                        }`,
                                                                    }}
                                                                >
                                                                    <div
                                                                        id="p-tooltip"
                                                                        className={isCopyToolkit ? "active" : null}
                                                                    >
                                                                        copied!
                                                                    </div>
                                                                </div>
                                                            </>
                                                        )}
                                                    </section>
                                                )}
                                        </section>
                                    )}
                                </section>
                                {" "}
                                {currentSection === "preset" && currentPreset && (
                                    <PresetImage
                                        currentCase={currentCase}
                                        currentPreset={currentPreset}
                                    />
                                )}
                            </section>
                            <section></section>
                        </section>

                        <SectionTitle currentSection={currentSection}></SectionTitle>

                        <aside
                            style={{paddingTop: "270px"}}
                            className="flex flex-col relative "
                        >
                            <section id="menu-mobile">
                                <SectionControl
                                    currentSection={currentSection}
                                    updateCurrentSectionState={handleSelectSection}
                                    currentPreset={currentPreset}
                                />
                            </section>
                            {windowSize.width > 1024 && (
                                <Camera
                                    productSelected={productSelected}
                                    imgUrl={"./images/tryonimage.png"}
                                    dataItemId={dataItemId}
                                    onReset={handleReset}
                                    currentSection={currentSection}
                                    currentPreset={currentPreset}
                                />
                            )}
                        </aside>
                    </section>
                    <section id="products"></section>
                </section>

                <section className="relative" id="mobile-version">
                    <h1 id="mobile-title" className="text-center uppercase">
                        virtual try-on {currentSection}
                    </h1>
                    {!currentProducts && !currentSection && (
                        <div>
                            <section id="menu-mobile">
                                <SectionControl
                                    currentSection={currentSection}
                                    updateCurrentSectionState={handleSelectSection}
                                />
                            </section>
                            <LoremIpsum/>
                        </div>
                    )}
                    {currentSection && (
                        <section className="menu-m-active">
                            {" "}
                            {currentSection !== "preset5" && (
                                <div>
                                    <SectionControl
                                        currentSection={currentSection}
                                        updateCurrentSectionState={handleSelectSection}
                                    />
                                </div>
                            )}
                            <div style={{width: currentSection === "preset5" && "50%"}}>
                                {currentProducts && currentSection && (
                                    <ProductsMobile
                                        currentSection={currentSection}
                                        currentPreset={currentPreset}
                                        updateCurrentPreset={setCurrentPreset}
                                        productSelected={productSelected}
                                        products={currentProducts}
                                        onSelectProduct={handleSelectProduct}
                                        updateDataItems={setDataItemId}
                                        updateCurrentCase={setCurrentCase}
                                        currentCase={currentCase}
                                    />
                                )}
                            </div>
                        </section>
                    )}
                    <Camera
                        productSelected={productSelected}
                        imgUrl={"./images/tryonimage.png"}
                        dataItemId={dataItemId}
                        onReset={handleReset}
                        currentSection={currentSection}
                        currentPreset={currentPreset}
                    />
                </section>

                <Checkout
                    product={productSelected}
                    currentSection={currentSection}
                    currentPreset={currentPreset}
                    currentCase={currentCase}
                    dataItemIds={dataItemId}
                />
            </main>
        </>
    );
}
